"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SystemWaterwayTypeEnum = exports.BaitType = exports.BusinessServiceTypeEnum = exports.PaymentMethodEnum = exports.PermitUnitTypeEnum = exports.NotifyPermitGuideEnum = void 0;
var NotifyPermitGuideEnum;
(function (NotifyPermitGuideEnum) {
    NotifyPermitGuideEnum["MANUAL"] = "MANUAL";
    NotifyPermitGuideEnum["WHEN_BOOKED"] = "WHEN_BOOKED";
    NotifyPermitGuideEnum["WHEN_ASSIGNED_TO_PERMIT"] = "WHEN_ASSIGNED_TO_PERMIT";
})(NotifyPermitGuideEnum || (exports.NotifyPermitGuideEnum = NotifyPermitGuideEnum = {}));
var PermitUnitTypeEnum;
(function (PermitUnitTypeEnum) {
    PermitUnitTypeEnum[PermitUnitTypeEnum["HalfHalf"] = 1] = "HalfHalf";
    PermitUnitTypeEnum[PermitUnitTypeEnum["FullDay"] = 2] = "FullDay";
    PermitUnitTypeEnum[PermitUnitTypeEnum["HalfDayAm"] = 3] = "HalfDayAm";
    PermitUnitTypeEnum[PermitUnitTypeEnum["HalfDayPm"] = 4] = "HalfDayPm";
})(PermitUnitTypeEnum || (exports.PermitUnitTypeEnum = PermitUnitTypeEnum = {}));
;
var PaymentMethodEnum;
(function (PaymentMethodEnum) {
    PaymentMethodEnum[PaymentMethodEnum["PaymentLink"] = 1] = "PaymentLink";
    PaymentMethodEnum[PaymentMethodEnum["WireTransfer"] = 2] = "WireTransfer";
    PaymentMethodEnum[PaymentMethodEnum["BankClaim"] = 3] = "BankClaim";
})(PaymentMethodEnum || (exports.PaymentMethodEnum = PaymentMethodEnum = {}));
;
var BusinessServiceTypeEnum;
(function (BusinessServiceTypeEnum) {
    BusinessServiceTypeEnum[BusinessServiceTypeEnum["LOGBOOK"] = 1] = "LOGBOOK";
    BusinessServiceTypeEnum[BusinessServiceTypeEnum["BOOKINGS"] = 2] = "BOOKINGS";
})(BusinessServiceTypeEnum || (exports.BusinessServiceTypeEnum = BusinessServiceTypeEnum = {}));
var BaitType;
(function (BaitType) {
    BaitType[BaitType["Flies"] = 1] = "Flies";
    BaitType[BaitType["Lures"] = 2] = "Lures";
    BaitType[BaitType["NaturalBaits"] = 3] = "NaturalBaits";
    BaitType[BaitType["Worm"] = 4] = "Worm";
})(BaitType || (exports.BaitType = BaitType = {}));
var SystemWaterwayTypeEnum;
(function (SystemWaterwayTypeEnum) {
    SystemWaterwayTypeEnum[SystemWaterwayTypeEnum["River"] = 1] = "River";
    SystemWaterwayTypeEnum[SystemWaterwayTypeEnum["Lake"] = 2] = "Lake";
})(SystemWaterwayTypeEnum || (exports.SystemWaterwayTypeEnum = SystemWaterwayTypeEnum = {}));
