import Sqids from 'sqids';

export type SidebarUrlParams = Record<'systemWaterwayId' | 'businessAccountId', string | number> & {
	locale: string;
};

export type GuideDetailUrlParams = SidebarUrlParams & {
	guideId: string | number;
}

export type BookingOfferDetailUrlParams = SidebarUrlParams & {
	bookingOfferId: string | number;
}

export type PermitDetailUrlParams = SidebarUrlParams & {
	permitId: string | number;
}

export type ProductDetailUrlParams = SidebarUrlParams & {
	productId: string | number;
}

const NON_DECODED_PARAMS = ['locale'];

const sqids = new Sqids({
	minLength: 8,
	alphabet: process.env.SQIDS_ALPHABET || '0123456789',
});

function formatParams<T extends Record<string, string | number | undefined>>(params: T) {

	return Object.entries(params).reduce<Record<string, string>>((acc, [key, value]) => {
		if (typeof value === 'string') {
			acc[key] = value;
		} else if (value) {
			acc[key] = sqids.encode([value]);
		}
		return acc;
	}, {});
}

export function encodeValue(value: string | number) {
	return sqids.encode([typeof value === 'string' ? parseInt(value) : value]);
}

export function decodeParams<T extends Record<string, string | number>>(params: Record<string, string>) {
	return Object.entries(params).reduce<Record<string, string | number>>((acc, [key, value]) => {
		if (NON_DECODED_PARAMS.includes(key)) {
			acc[key] = value;
		} else if (value) {
			acc[key] = sqids.decode(value)[0];
		}
		return acc;
	}, {}) as T;
}

export function businessAccountUrl(params: Omit<SidebarUrlParams, 'systemWaterwayId'>) {
	const formattedParams = formatParams(params);

	return `/${formattedParams.locale}/${formattedParams.businessAccountId}`;
}

export function registerCompanyUrl(params: Pick<SidebarUrlParams, 'locale'>) {
	const formattedParams = formatParams(params);

	return `/${formattedParams.locale}/register-company`;
}

export function permitsUrl(params: SidebarUrlParams) {
	const formattedParams = formatParams(params);

	return `/${formattedParams.locale}/${formattedParams.businessAccountId}/permits/${formattedParams.systemWaterwayId}`;
}

export function createPermitUrl(params: SidebarUrlParams) {
	return permitDetailUrl({ ...params, permitId: 'new' });
}

export function permitDetailUrl(params: PermitDetailUrlParams) {
	const formattedParams = formatParams(params);

	return `/${formattedParams.locale}/${formattedParams.businessAccountId}/permits/${formattedParams.systemWaterwayId}/${formattedParams.permitId}`;
}

export function bookingsUrl(params: SidebarUrlParams) {
	const formattedParams = formatParams(params);

	return `/${formattedParams.locale}/${formattedParams.businessAccountId}/permits/${formattedParams.systemWaterwayId}/bookings`;
}

export function bookingOfferDetailUrl(params: BookingOfferDetailUrlParams) {
	const formattedParams = formatParams(params);

	return `/${formattedParams.locale}/${formattedParams.businessAccountId}/permits/${formattedParams.systemWaterwayId}/bookings/${formattedParams.bookingOfferId}`;
}

export function guidesUrl(params: SidebarUrlParams) {
	const formattedParams = formatParams(params);

	return `/${formattedParams.locale}/${formattedParams.businessAccountId}/permits/${formattedParams.systemWaterwayId}/guides`;
}

export function guideDetailUrl(params: GuideDetailUrlParams) {
	const formattedParams = formatParams(params);

	return `/${formattedParams.locale}/${formattedParams.businessAccountId}/permits/${formattedParams.systemWaterwayId}/guides/${formattedParams.guideId}`;
}

export function createGuideUrl(params: SidebarUrlParams) {
	return guideDetailUrl({ ...params, guideId: 'new' });
}

export function productDetailUrl(params: ProductDetailUrlParams) {
	const formattedParams = formatParams(params);

	return `/${formattedParams.locale}/${formattedParams.businessAccountId}/permits/${formattedParams.systemWaterwayId}/products/${formattedParams.productId}`;
}

export function productsUrl(params: SidebarUrlParams) {
	const formattedParams = formatParams(params);

	return `/${formattedParams.locale}/${formattedParams.businessAccountId}/permits/${formattedParams.systemWaterwayId}/products`;
}

export function createProductUrl(params: SidebarUrlParams) {
	return productDetailUrl({ ...params, productId: 'new' });
}

export function customersUrl(params: SidebarUrlParams) {
	const formattedParams = formatParams(params);

	return `/${formattedParams.locale}/${formattedParams.businessAccountId}/permits/${formattedParams.systemWaterwayId}/customers`;
}

export function customerDetailUrl(params: SidebarUrlParams & { customerId: string | number }) {
	const formattedParams = formatParams(params);

	return `/${formattedParams.locale}/${formattedParams.businessAccountId}/permits/${formattedParams.systemWaterwayId}/customers/${formattedParams.customerId}`;
}

export function createCustomerUrl(params: SidebarUrlParams) {
	return `${customersUrl(params)}/new`;
}

export function createInvoiceUrl(params: BookingOfferDetailUrlParams) {
	return `${bookingOfferDetailUrl(params)}/invoices/new`;
}

export function permitSeasonsUrl(params: SidebarUrlParams) {
	const formattedParams = formatParams(params);

	return `/${formattedParams.locale}/${formattedParams.businessAccountId}/permits/${formattedParams.systemWaterwayId}/seasons`;
}

export function createPermitSeasonUrl(params: SidebarUrlParams) {
	const formattedParams = formatParams(params);

	return `/${formattedParams.locale}/${formattedParams.businessAccountId}/permits/${formattedParams.systemWaterwayId}/seasons/new`;
}

export function permitSettingsUrl(params: SidebarUrlParams, subPath?: string) {
	const formattedParams = formatParams(params);

	return `/${formattedParams.locale}/${formattedParams.businessAccountId}/permits/${formattedParams.systemWaterwayId}/settings${subPath ? `/${subPath}` : ''}`;
}



export function accountSettingsUrl(params: Omit<SidebarUrlParams, 'systemWaterwayId'>, subPath?: string) {
	const formattedParams = formatParams(params);

	return `/${formattedParams.locale}/${formattedParams.businessAccountId}/account-settings${subPath ? `/${subPath}` : ''}`;
}

export function logbooksUrl(params: SidebarUrlParams) {
	const formattedParams = formatParams(params);

	return `/${formattedParams.locale}/${formattedParams.businessAccountId}/logbooks/${formattedParams.systemWaterwayId}`;
}

export function logbookCatchesUrl(params: SidebarUrlParams, subPath?: string) {
	const formattedParams = formatParams(params);

	return `/${formattedParams.locale}/${formattedParams.businessAccountId}/logbooks/${formattedParams.systemWaterwayId}/catches${subPath ? `/${subPath}` : ''}`;
}

export function logbookStatisticsUrl(params: SidebarUrlParams) {
	const formattedParams = formatParams(params);

	return `/${formattedParams.locale}/${formattedParams.businessAccountId}/logbooks/${formattedParams.systemWaterwayId}/statistics`;
}

export function logbookSpotsUrl(params: SidebarUrlParams) {
	const formattedParams = formatParams(params);

	return `/${formattedParams.locale}/${formattedParams.businessAccountId}/logbooks/${formattedParams.systemWaterwayId}/spots`;
}

export function logbookReportsUrl(params: SidebarUrlParams) {
	const formattedParams = formatParams(params);

	return `/${formattedParams.locale}/${formattedParams.businessAccountId}/logbooks/${formattedParams.systemWaterwayId}/reports`;
}

export function logbookLogLinksUrl(params: SidebarUrlParams) {
	const formattedParams = formatParams(params);

	return `/${formattedParams.locale}/${formattedParams.businessAccountId}/logbooks/${formattedParams.systemWaterwayId}/log-links`;
}

export function logbookSeasonsUrl(params: SidebarUrlParams) {
	const formattedParams = formatParams(params);

	return `/${formattedParams.locale}/${formattedParams.businessAccountId}/logbooks/${formattedParams.systemWaterwayId}/seasons`;
}

export function createLogbookSeasonUrl(params: SidebarUrlParams) {
	const formattedParams = formatParams(params);

	return `/${formattedParams.locale}/${formattedParams.businessAccountId}/logbooks/${formattedParams.systemWaterwayId}/seasons/new`;
}

export function logbookSettingsUrl(params: SidebarUrlParams, subPath?: string) {
	const formattedParams = formatParams(params);

	return `/${formattedParams.locale}/${formattedParams.businessAccountId}/logbooks/${formattedParams.systemWaterwayId}/settings${subPath ? `/${subPath}` : ''}`;
}



export function logCatchUrl(params: SidebarUrlParams) {
	const formattedParams = formatParams(params);

	return `/${formattedParams.locale}/${formattedParams.businessAccountId}/logbooks/${formattedParams.systemWaterwayId}/catches/log-catch`;
}


// Auth

export function loginUrl(params: { locale: string }) {
	const formattedParams = formatParams(params);

	return `/${formattedParams.locale}/login`;
}

export function passwordChangeUrl(params: { locale: string }) {
	const formattedParams = formatParams(params);

	return `/${formattedParams.locale}/password/change`;
}
