import type { AIQModel } from '@aiq/types';


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function unique<T extends Record<string, any>>(arr: T[], uniqueBy: keyof T): T[] {
	const obj: { [key in keyof T]?: T } = {};
	const arrByElem = arr.map(x => {
		obj[x[uniqueBy] as keyof typeof obj] = x;

		return x[uniqueBy];
	}); // Preserve order of elements

	// https://stackoverflow.com/questions/16747798/delete-duplicate-elements-from-an-array
	const uniqElem = arrByElem.filter(function (elem, index, self) {
		return index === self.indexOf(elem);
	});

	return uniqElem.map(x => obj[x as keyof typeof obj]) as T[];
}

export function fillArray(N: number): number[] {
	let i = 0;
	const a = Array(N);

	while (i < N) {
		a[i++] = i;
	}

	return a;
}

export function objectByRemovingKeys<T extends Record<string, unknown>, K extends keyof T>(obj: T, keys: K[]): Omit<T, K> {
	const newObj = { ...obj };

	for (const key of keys) {
		delete newObj[key];
	}

	return newObj;
}

export function itemsByReplacingItem<T extends AIQModel>(items: T[], item: T) {
	return [...items].map(x => {
		if (x.id === item.id) {
			return { ...item };
		}

		return x;
	});
}

export function itemsByDeletingItem<T extends AIQModel>(items: T[], item: T) {
	return items.filter(x => {
		if (x.id === item.id) {
			return false;
		}

		return true;
	});
}

export function generateUniqueRandomNumbers(n: number, x: number): number[] {
	if (n > x) {
		console.warn('number of elements must be less than or equal to the number of possible values');
		return [];
	}

	const possibleValues = Array.from({ length: x }, (_, i) => i);

	for (let i = x - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[possibleValues[i], possibleValues[j]] = [possibleValues[j], possibleValues[i]];
	}

	return possibleValues.slice(0, n);
}

export function shuffleArray(array: unknown[]) {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
}
