import type { Resource } from 'i18next';
import { createInstance } from 'i18next';
import { initReactI18next } from 'react-i18next/initReactI18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import i18nConfig from '../../18n.config';

export const i18nOptions = (lng?: string) => ({
	lng,
	fallbackLng: i18nConfig.defaultLocale,
	supportedLngs: i18nConfig.locales,
	defaultNS: 'common',
	fallbackNS: 'common',
	preload: i18nConfig.locales
});



export function initTranslations(locale?: string, resources?: Resource) {
	const i18nInstance = createInstance();
	i18nInstance.use(initReactI18next);

	i18nInstance.use(
		resourcesToBackend(
			(language: string, namespace: string) =>
				import(`locales/${language}/${namespace}.json`)
		)
	);

	i18nInstance.init({
		...i18nOptions(locale),
		ns: ['common', 'app', 'baitTypes', 'countries', 'errors', 'fishingMethods', 'messages', 'paymentMethods', 'species', 'weatherDescriptions'],
		resources
	});

	return {
		i18n: i18nInstance,
		resources: i18nInstance.services.resourceStore.data,
		t: i18nInstance.t
	};
}

// Use server side
export async function useTranslation(locale: string, namespaces: string | string[] = 'common', resources?: Resource) {
	const i18nInstance = createInstance();

	i18nInstance.use(initReactI18next);

	if (!resources) {
		i18nInstance.use(
			resourcesToBackend(
				(language: string, namespace: string) =>
					import(`locales/${language}/${namespace}.json`)
			)
		);
	}

	await i18nInstance.init({
		...i18nOptions(locale),
		resources,
		ns: namespaces,
		preload: resources ? [] : i18nConfig.locales
	});

	return {
		i18n: i18nInstance,
		resources: i18nInstance.services.resourceStore.data,
		t: i18nInstance.getFixedT(locale, Array.isArray(namespaces) ? namespaces[0] : namespaces),
	};
}